import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [
  { key: 'my-account', text: 'My Account', url: '/my-account' }
]

const MyAccountPage = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>My Account Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default MyAccountPage
